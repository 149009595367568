/* eslint-disable */
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';

// import pages

const NotFound = () => import('@/pages/error/NotFound.vue');
const AuthLogin = () => import('@/pages/auths/AuthLogin');


const dashboardView = () => import('@/pages-smartbase/dashboard/dashboardView.vue');


const ProductList = () => import('@/pages-smartbase/product/productList.vue');
const ProductCreate = () => import('@/pages-smartbase/product/productCreate.vue')
const ProductEdit = () => import('@/pages-smartbase/product/productEdit.vue')
const ProductView = () => import('@/pages-smartbase/product/productView.vue')







const AdminModelCreate = () => import('@/pages-smartbase/admin/models/modelCreate.vue')
const AdminModelList = () => import('@/pages-smartbase/admin/models/modelList.vue')
const AdminModelEdit = () => import('@/pages-smartbase/admin/models/modelEdit.vue')


const AdminKeyList = () => import('@/pages-smartbase/admin/keys/keyList.vue')

const configView = () => import("@/pages-smartbase/config/configView.vue");


const routes = [
  {
    path: '/',
    name: 'Blank',
    meta: { requiresKey : true},
    component: AuthLogin
  },
  {
    path: '/admin/dashboard',
    name: 'dashboard List',
    component: dashboardView
  },
  {
    path: '/config/',
    name: 'config View',
    component: configView
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },
  {
    path: '/admin/model/new',
    name: 'New Attendant',
    component: AdminModelCreate
  },
  {
    path: '/admin/models',
    name: 'Attendant List',
    component: AdminModelList
  },
  {
    path: '/admin/keys',
    name: 'Key List',
    component: AdminKeyList
  }, 
  {
    path: '/admin/model/:id',
    name: 'Model Edit',
    component: AdminModelEdit
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: AuthLogin 
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
import {store, refreshSession} from '../state/store'
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`


  if (to.path.includes('toSite')) {
    window.location = 'https://thebww.com/'
    return;
  }


  //alert(to.meta.requiresKey);
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login','/auths/auth-reset'];
  const isAuthenticated = localStorage.getItem('nioBoardAuthenticated');
  if(publicRoutes.includes(to.path) || store.state.session.logged){
    next();
  }else{
    next('/auths/auth-login');
  }
})

export default router
