import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import {store } from "./state/store";
var baseUrl = (process.env.VUE_APP_ENV == 'local' ? 'http://localhost:3000' : window.location.origin);
store.commit('setApiUrl', `${baseUrl}/api`);

console.log('oi');
console.dir(process.env);
// vue app
const app = createApp(App);

// bootstrap
import "bootstrap";

// template custom css
import './assets/scss/style.scss';




import { Colors, monthList, today, yesterday, currentMonth } from './utilities/globalProperties.js'
import attr from '@/utilities/attr'

app.config.globalProperties.$Colors = Colors;
app.config.globalProperties.$monthList = monthList;
app.config.globalProperties.$today = today;
app.config.globalProperties.$yesterday = yesterday;
app.config.globalProperties.$currentMonth = currentMonth;
// custom directive alert 
app.directive('nk-alert', {
    mounted(elem, binding) {
        let alertTemplate = function(selector, message, variant) {
            const target = document.getElementById(selector)
            const wrapper = document.createElement('div')
            wrapper.innerHTML = `<div class="alert alert-${variant} alert-dismissible" role="alert">
                    <div>${message}</div>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>`
            target.append(wrapper);
        };

        elem.addEventListener('click', function () {
            alertTemplate(binding.value.target, binding.value.content, binding.arg || 'primary')
        })
    }
});


// custom directive for Modal
import { Modal } from 'bootstrap';

// Modal
app.directive('nk-modal', {
    mounted(elem, binding) {
        const modalId = elem.getAttribute('href') || binding.value
        const modal = new Modal(modalId);

        elem.addEventListener('click', function (e) {
            e.preventDefault();
            modal.show();
        })
    }
});



//CHARTS
import HighchartsVue from 'highcharts-vue'
app.use(HighchartsVue, {tagName: 'charts'})


// custom directive for Offcanvas
import { Offcanvas } from 'bootstrap';

// Offcanvas
app.directive('nk-offcanvas', {
    mounted(elem, binding) {
        const offcanvasId = elem.getAttribute('href') || binding.value
        const offcanvas = new Offcanvas(offcanvasId);

        elem.addEventListener('click', function (e) {
            e.preventDefault();
            offcanvas.show();
        })
    }
});

// custom directive for Tab
import { Tab } from 'bootstrap';

// Tab
app.directive('nk-tab', {
    mounted(elem, binding) {
        if(binding.value){
            let setAttrName = elem.tagName === 'a' ? 'href' : 'data-bs-target'
            attr(elem,setAttrName,binding.value);
        }
        const tab = new Tab(elem);
        elem.addEventListener('click', function (e) {
            e.preventDefault();
            tab.show();
        })
    }
});

// custom directive toast
import { Toast } from 'bootstrap';

app.directive('nk-toast', {
    mounted(elem, binding){
        const toastLive = document.getElementById(binding.value);
        elem.addEventListener('click', function () {
            const toast = new Toast(toastLive);
            toast.show();
        })
    }
})

import { useAccordion } from "vue3-rich-accordion";
//import "vue3-rich-accordion/accordion-library-styles.css";
import './assets/scss/accordion-styles.scss'
app.use(useAccordion);



import 'vue3-toastify/dist/index.css';
// custom directive for Tooltip
import { Popover } from 'bootstrap';

// popover
app.directive('nk-popover', {
    mounted(elem, binding) {
        new Popover(elem, {
            placement: binding.arg || 'top',
            title: binding.value.title !== undefined ? binding.value.title : '',
            content: binding.value.content !== undefined ? binding.value.content : '',
            customClass: binding.value.customClass !== undefined ? binding.value.customClass : '',
            trigger:binding.value.trigger !== undefined ? binding.value.trigger : 'click',
            container:binding.value.container !== undefined ? binding.value.container : 'body',
            offset:binding.value.offset !== undefined ? binding.value.offset : '0,8',
            html:true,
        });
    }
});





import  currency  from 'currency.js'
app.config.globalProperties.$filters = {
    toPorcentage(value) {
        var valor = value;
  
        if(valor == null || valor == undefined) 
          valor = 0;

        return `${valor.toFixed(2)}%`;
    },


    toCurrency(value) {
        var valor = value;
  
        if(valor == null) 
          valor = 0;
      
        if (typeof valor !== "number") {
            value = parseFloat(value);
            if(isNaN(value)) 
              return value;
        }
      /*  var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 3
        });

*/
        const USD = value => currency(value, { symbol: "$", precision: 3 });
        return USD(value).format();
    }
}

// custom directive for Tooltip
import { Tooltip } from 'bootstrap';

// tooltip
app.directive('nk-tooltip', {
    mounted(elem, binding) {
        new Tooltip(elem, {
            placement: binding.arg || 'top',
            title: binding.value,
            html: true
        });
    }
});





import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
app.use(vueAwesomeSidebar)


import MyPopper from "vue3-popper";
app.component("MyPopper", MyPopper);



import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {far}  from '@fortawesome/free-regular-svg-icons'
import {fas}  from '@fortawesome/free-solid-svg-icons'
library.add(fas)
library.add(far)
app.component('font-awesome-icon', FontAwesomeIcon)


app.use(store);


store.commit('setVersion', process.env.VUE_APP_VERSION);
store.commit('setEnv', process.env.VUE_APP_ENV);

store.commit('setAppDetails', { 
    version     : null,
    build       : process.env.VUE_APP_VERSION,
    buildDate   : process.env.VUE_APP_RELEASE_DATE,
    env         : process.env.VUE_APP_ENV,
})


const logged = !(!localStorage.getItem('bww-login'));
if(logged){
    const refreshToken = JSON.parse(localStorage.getItem('bww-login')).refreshToken;
    const jwt = JSON.parse(localStorage.getItem('bww-login')).jwt;
    const sessionId = JSON.parse(localStorage.getItem('bww-login')).sessionId;
    store.commit('setLogin', {jwt,refreshToken, sessionId});
}


app.use(router).mount('#app');