import { createStore } from 'vuex'
import ConfigMixing from '../mixings/ConfigMixing';


    const navigationModule = {
    state: () => ({ 
        pageTitle     : '',
        pageIcon      : '',
        tempProduct    : null,
        tempRelationship: null,
        tempPurchaseOrder: null,
        tempAdjustment: null,
        tempStockTransfer: null,
        tempRegisterSession:null,
        tempSalesOrder : null
    }),
        mutations: {
        setTempRegisterSession(state, tempRegisterSession){
            state.tempRegisterSession = tempRegisterSession;
        },
        setTempStockTransfer(state, tempStockTransfer){
            state.tempStockTransfer = tempStockTransfer;
        },
        setTempAdjustment(state, tempAdjustment){
            state.tempAdjustment = tempAdjustment;
        },
        setTempRelationship(state, tempRelationship){
            state.tempRelationship = tempRelationship;
        },
        setTempSalesOrder(state, tempSalesOrder){
            state.tempSalesOrder = tempSalesOrder;
        },
        setTempPurchaseOrder(state, tempPurchaseOrder){
            state.tempPurchaseOrder = tempPurchaseOrder;
        },
        setTempProduct(state, tempProduct){
            state.tempProduct = tempProduct;
        },
        setPageIcon(state, icon){
            state.pageIcon = icon;
        },
        setPageTitle(state, title){
            state.pageTitle = title;
        },
    }
    }

    const moduleConfig = {
        state: () => ({ 
            version     : null,
            build       : null,
            buildDate: null,
            headerAlert : null,
            qbHrefCustomer: null,
            qbHrefBill: null,
            qbHrefVendor: null,
            qbHrefInvoice: null,
            qbHrefPayment: null,
            apiUrl      :null, 
            env: null,
            isQbOnline : false,
            locations: [],
            registers: [],
            printers:[],
            paymentMethods:[],
            taxTypes   : [],
            paymentTerms: {},
            unities     : [],
            licensedFor : '',
            logoWhite   : '',
            isProd      : '0',
        }),
        mutations: {
            setHeaderAlert(state, alert) {
                state.headerAlert = alert
            },
            setApiUrl(state, apiUrl) {
                state.apiUrl = apiUrl
            },
            setVersion(state, version){
                state.version = version
            },
            setPrinters(state, printers){
                state.printers = printers
            },
            setRegisters(state, registers){
                state.registers = registers
            },
            setUnities(state, unities){
                state.unities = unities
            },
            setPaymentMethods(state, paymentMethods){
                state.paymentMethods = paymentMethods
            },
            setPaymentTerms(state, paymentTerms){
                state.paymentTerms = paymentTerms
            },
            setLocations(state, locations){
                state.locations = locations
            },
            setEnv(state, env){
                state.env = env;
            },
            setIsProd(state, is){
                state.isProd = (is === 'true');
            },
            setLogoWhite(state, url){
                state.logoWhite = url;
            },
            setLicensedFor(state, name){
                state.licensedFor = name;
            },
            setIsQbOnline(state, is){
                state.isQbOnline = is;
            },
            setConfig(state, appDetails){
                state = appDetails;
            },
            setQbhrefCustomer(state, href){
                state.qbHrefCustomer = href;
            },
            setQbhrefVendor(state, href){
                state.qbHrefVendor = href;
            },
            setQbhrefInvoice(state, href){
                state.qbHrefInvoice = href;
            },
            setQbhrefPayment(state, href){
                state.qbHrefPayment = href;
            },
            
            setQbhrefBill(state, href){
                state.qbHrefBill = href;
            },
            setTaxTypes(state, taxTypes){
                state.taxTypes = taxTypes;
            }          
        }
    }

    const moduleSession = {
        state: () => ({ 
            lastRefresh     : null,
            logged          : false,
            jwt             : null,
            userName        : null,
            userId          : null,
            sessionId       : null,
            refreshToken    : null,
        }),
        mutations: {
            setLogged(state, { logged }){
                state.logged = logged;
            },
            setLastRefresh(state){
                state.lastRefresh = new Date();
            },
            setLogout(state){
                state.jwt = null;
                state.logged = false;
                state.sessionId = null;
                state.refreshToken = null;  
                localStorage.removeItem('bww-login');
            },
            setLogin(state, { jwt, refreshToken, sessionId }){
                state.jwt = jwt;
                state.logged = true;
                state.sessionId = sessionId;
                state.refreshToken = refreshToken;
                getUserInfo();
                persistSession();
            },
            setUserName(state, userName){
                state.userName = userName;
            },
            setUserId(state, userId){
                state.userId = userId;
            },
            setRefreshToken(state, refreshToken){
                state.refreshToken = refreshToken;
            },
            setSessionId(state, sessionId){
                state.sessionId = sessionId;
            }
        }
    }

    const store = createStore({
        modules: {
            session : moduleSession,
            config:  moduleConfig,
            navigation : navigationModule
        }
    })

        function  loadConfig(){
            return new Promise( (resolve, reject) => {
                try{
                    ConfigMixing.config().then(function(c){
                        var user = c.data.user;
                        if (user) {
                            store.commit('setUserName', user.userName);
                            store.commit('setUserId', user.userId);
                        }

                        store.commit('setConfig', c.data.config);
                        store.commit('setLocations', c.data.locations);
                        store.commit('setRegisters', c.data.registers);
                        store.commit('setPrinters', c.data.printers);
                        store.commit('setUnities', c.data.unities);
                        store.commit('setIsQbOnline', c.data.quickBooks.active);
                        store.commit("setTaxTypes", c.data.taxTypes);
                        store.commit("setPaymentMethods", c.data.paymentMethods);

                        
                        store.commit('setIsProd',c.data.system.production);
                        store.commit('setLogoWhite', c.data.system.logoWhiteUrl);
                        store.commit('setLicensedFor', c.data.system.licensedFor);
                        store.commit('setHeaderAlert', c.data.system.alertMessage);
                        

                        store.commit('setQbhrefCustomer', c.data.quickBooks.hrefCustomer);
                        store.commit('setQbhrefVendor', c.data.quickBooks.hrefVendor);
                        store.commit('setQbhrefBill', c.data.quickBooks.hrefBill);
                        store.commit('setQbhrefInvoice', c.data.quickBooks.hrefInvoice);
                        store.commit('setQbhrefPayment', c.data.quickBooks.hrefPayment);

                        store.commit('setPaymentTerms', c.data.paymentTerms);
                        resolve(true);
                    }).catch(function(){
                        reject(false);
                    })
                } catch{
                    reject(false);
                }
            });
        }

    const getUserInfo = () => {
        console.log(`Loading Config...`);
        ConfigMixing.config().then(function(c){
            console.log(`Config OK`);
            var user = c.data.user;
            store.commit('setUserName', user.userName);
            store.commit('setUserId', user.userId);
            store.commit('setConfig', c.data.config);
            store.commit('setLocations', c.data.locations);
            store.commit('setPaymentTerms', c.data.paymentTerms)
        }).catch(function(){
            console.error(`Error while loading config...`);
        })
    }
    
   
    const persistSession = () =>{
        return localStorage.setItem('bww-login', JSON.stringify({
            jwt : store.state.session.jwt,
            sessionId : store.state.session.sessionId,
            refreshToken : store.state.session.refreshToken
        }));
    };






export { store, loadConfig};